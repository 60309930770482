@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Base Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Font Smoothing */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Roboto', sans-serif !important;
}

/* Code Block Styling */
code {
  font-family: 'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono',
    'Source Code Pro', monospace;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
}

/* Prevent Mobile Text Size Adjustment */
html {
  -webkit-text-size-adjust: 100%;
}

/* Remove Tap Highlight on Mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Better Image Rendering */
img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}
